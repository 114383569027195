.box{
  display: inline-block;
  padding: 0.15rem 0.4rem 0.12rem;
  @include mq(md){
    padding: 0.3rem 0.8em;
  }
  min-width: 4.375rem;
  text-align: center;
  & > div > div{
    text-align: left;
  }
  font-weight: 500;
  color: var(--color-black);
  border: 1px solid;
  position: relative;
  &.width-2{
    width: calc(4.375rem * 2 + 1em);
  }
  &.width-100{
    width: 100%;
  }
  &.bkg-white{
    background-color: white;
  }
}

a.box, button.box{
  @media (hover: hover) {
    &:hover{
      &:after{
        content: "";
        position: absolute;
        height: 1px;
        background-color: black;
        width: 100%;
        left:0;
        bottom: -0.591em;
      }
    }
  }
}

.box--black-hover{
  @media (hover: hover) {
    &:hover{
      background-color: black;
      color: white;
      border-color: black;
      &:after{
        content: none !important;
      }
    }
  }
}

.box--grey{
  @extend .box;
  color: var(--color-gray-500) !important;
  border-color: var(--color-gray-300);
  @include notouch{
    &:hover{
     a{color: black};
    }
  }
  a{
    color: var(--color-gray-500);

  }
}

.box--xs-button{
  min-height: 3em;
  @include mq(md){
    min-height: unset;
  }
}
.sold-out-box{
  display: flex;
  align-items: center;
  justify-content: center;
}

.box--grey--outline{
  @extend .box;
  color: black;
  border-color: var(--color-gray-300);
  &.product--price--box{
    display: flex;
    justify-content: space-between;
  }
  &.product--title--box{
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
}

