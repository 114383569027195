main.links{
  padding: 7.5rem calc(1 * var(--base-space)) calc(1 * var(--base-space));
  @include mq(md){
    padding-top: 7.5rem;
    padding-bottom: 1.5rem;
  }
}

.links--text-container{
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-column: 3/9;
  align-content: start;
  text-align: center;
  a:first-of-type{
    .link-entry{
      border-top: 1px solid black;
    }

  }
  .link-entry{
    @include notouch{
      &:hover{
        color: var(--color-gray-500);
      }
    }
    @extend .box;
    padding-left: 0;
    padding-right: 0;
    border: 0;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    border-bottom: 1px solid black;
    p:first-of-type{
      text-align: left;
    }
    p:last-of-type{
      text-align: right;
    }
  }
}

.about--credits{
  grid-column: 3/9;
  align-self: end;
  text-align: center;
}
