$icons-font: "icons";

@font-face {
  font-family: $icons-font;
  src: url("../fonts/icons.ttf?340d634a0c977e9f39d90a16ff28fa4a") format("truetype"),
  url("../fonts/icons.woff?340d634a0c977e9f39d90a16ff28fa4a") format("woff"),
  url("../fonts/icons.woff2?340d634a0c977e9f39d90a16ff28fa4a") format("woff2");
}

i{
  position: relative;
}

i[class^="icon-"]:before, i[class*=" icon-"]:before {
  font-family: icons !important;
  font-style: normal;
  font-weight: normal !important;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 2.5em;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

$icons-map: (
  "cross": "\f101",
  "smile": "\f102",
);

.icon-cross:before {
  content: map-get($icons-map, "cross");
}
.icon-smile:before {
  content: map-get($icons-map, "smile");
}
