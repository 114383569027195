// CONFIGURATE DOCUMENT

$fontsize: (
  main: (
    xs:(
      fs: 10px,
      lh: 14px,
    ),
    sm: (
      fs: 10px,
      lh: 1.27em,
    ),
    md: (
      fs: 10.8px,
      lh: 1.27em,
    ),
  ),
  h1: (
    xs:(
      fs: 22.5px,
      lh: 22.5px,
    ),
    sm: (
      fs: 22.5px,
      lh: 22.5px,
    ),
    md: (
      fs: 36px,
      lh: 36px,
    ),
  ),
  h2: (
    xs:(
      fs: 24px,
      lh: 24px,
    ),
    sm: (
      fs: 24px,
      lh: 24px,
    ),
    md: (
      fs: 24px,
      lh: 24px,
    ),
  ),
  text: (
    xs:(
      fs: 14px,
      lh: 1.3em,
    ),
    sm: (
      fs: 14px,
      lh: 1.3em,
    ),
    md: (
      fs: 17px,
      lh: 1.3em,
    ),
  ),
  text-l: (
    xs:(
      fs: 14px,
      lh: 1.35em,
    ),
    sm: (
      fs: 14px,
      lh: 1.35em,
    ),
    md: (
      fs: 21px,
      lh: 1.35em,
    ),
  ),
);

$margin: (
  sm: 1,
);

:root {
  --base-space: 1rem;
  --color-text: black;
  --color-link: black;
  --color-hover: black;
  --color-bg: white;
}

// __________________________________________________________ //

// FONTS

$fonts: (
  main: (
    name: zurichmmregular,
    fallback: "Helvetica Neue, Helvetica, Arial, Lucida Grande, sans-serif",
    withFile: true,
  ),
  h1:(
    name: startseitemmregular,
    fallback: "Helvetica Neue, Helvetica, Arial, Lucida Grande, sans-serif",
    withFile: true,
  ),
  h2:(
    name: startseitemmregular,
    fallback: "Helvetica Neue, Helvetica, Arial, Lucida Grande, sans-serif",
    withFile: true,
  ),
  text:(
    name: lifemmregular,
    fallback: "Helvetica Neue, Helvetica, Arial, Lucida Grande, sans-serif",
    withFile: true,
  )
);

// __________________________________________________________ //

// BREAKPOINTS

$breakpoints: (
  xs: 0px,
  sm: 576px,
  md: 1024px,
  mdl: 1400px,
);

// __________________________________________________________ //

// TRANSITION

$transition-property-default: all;
$transition-duration-default: 0.3s;
$transition-timing-default: cubic-bezier(0.7, 0, 0.3, 1);
$transition-delay-default: 0s;

// __________________________________________________________ //

// FREE CONFIG


