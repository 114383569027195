main.clippings{
  padding: 7.5rem calc(1 * var(--base-space));
  @include mq(md){
    padding-top: 7.5rem;
    padding-bottom: 1.5rem;
  }
}

.clippings-grid{
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-column: 3/9;
}

.clipping-item{
  .box.width-100{
    display: none;
    @include mq(md){
      display: flex;
    }
  }
  @include mq(md){
    padding: 0 0.75rem 0.5rem 0.75rem;
    border-left: 1px solid;
    border-right: 1px solid;
  }
  grid-column: span 3;
  &:nth-of-type(odd){
    padding-right: 0.5rem;
    @include mq(md){
      padding-right: 0.75rem;
    }
    border-right: 1px solid;
  }
  &:nth-of-type(even){
    padding-left: 0.5rem;
    @include mq(md){
      padding-left: 0.75rem;
    }
  }
  &:nth-of-type(1) .clipping-item-title{
    margin-top: 0;
    @include mq(md){
      margin-top: 1rem;
    }
  }
  &:nth-of-type(2) .clipping-item-title{
    margin-top: 0;
    @include mq(md){
      margin-top: 1rem;
    }
  }
  a{
    display: flex;
    flex-direction: column;
    height: 100%;
  }
  .clipping-item-cover img{
      height: auto;
  }
  .clipping-item-title{
    margin: 0.5rem 0 0.6rem;
    text-align: center;
    @include font(main);
    @include fs(main);
    display: flex;
    align-items: start !important;
    justify-content: center !important;
    @extend .box;
    @include mq(md){
      margin: 1rem 0 1.1rem;
      @include font(h1);
      @include fs(h1);
      flex: none !important;
      border: none !important;
      padding: 0 !important;
    }
  }
  .clipping-item-abstract{
    margin-top: 0.25rem;
    @include font(text);
    font-size: 11.5px;
    line-height: 1.3em;
    @include mq(md){
      @include font(text);
      @include fs(text);
    }
  }
  &:nth-of-type(even){
    border-left: none;
  }
  .box{
    flex: 1;
    display: flex;
    justify-content: space-between;
    align-items: end;
  }
}
