main.clipping{
  padding: 7rem calc(1 * var(--base-space)) calc(1 * var(--base-space));
  @include mq(md){
    padding-top: 7rem;
    padding-bottom: 1.5rem;
  }

}

.clipping-article{
  grid-column: span 6;
  h1{
    text-align: center;
  }
  .cover-image{
    margin: 0 auto;
    margin-top: 1.8rem;
    margin-bottom: 1.7rem;
    picture, img{
      display: block;
      margin: 0 auto;
    }
    @include mq(md){
      max-width: 70%;
    }
    picture.portrait{
      max-width: 70%;
      @include mq(md){
        max-width: unset;
      }
    }
    picture.square{
      max-width: 70%;
      @include mq(md){
        max-width: unset;
      }
    }
  }
  .cover-image--legend{
    text-align: center;
    margin-top: 0.5rem;
  }
  .block-type-markdown{
    @include font(text);
    @include fs(text-l);
    margin-top: 1.25em;
    margin-bottom: 1.8em;
  }
  .block-type-image{
    margin: 0 auto;
    margin-bottom: 1.7em;
    picture, img{
      display: block;
      margin: 0 auto;
    }
    picture.portrait{
      max-width: 70%;
      @include mq(md){
        max-width: unset;
      }
    }
    picture.square{
      max-width: 70%;
      @include mq(md){
        max-width: unset;
      }
    }
    @include mq(md){
      max-width: 70%;
    }
  }
  .back-button{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: auto !important;
    margin-right: auto !important;
    .box{
      margin-bottom: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 3.875rem;
    }
  }
}

.block-type-image + .block-type-image{
  margin-top: 4.5em;
}

.caption{
  text-align: center;
  margin: 1em 0;
}

main.clipping .links--text-container{
  margin-top: 5.4rem;
}
