main.product{
  padding-top: 7.5rem;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-bottom: 1rem;
  box-sizing: border-box;
  grid-template-rows: max-content;
  display: grid;
  @include mq(md){
    padding-right: 0rem;
    padding-left: 0rem;
    padding-top: 7.5rem;
    padding-bottom: 1.5rem;
    overflow: hidden;
    grid-template-rows: 1fr;
  }
}

.product--info{
  margin-top: 4rem;
  @include mq(md){
    margin-top: 0;
    height: 100%;
    @include padding(0 1.5);
  }
}

.product--info button[data-action='add-to-cart']{
  min-height: 3em;
  @include mq(md){
    min-height: auto;
  }
}

.product--info-inner{
  position: sticky;
  top:0;
}

.box.width-2{
  @include margin(1 0);
  @include mq(md){
    @include margin(2 0);
  }
}

.product--data{
  p:nth-of-type(even){
    margin-bottom: 1em;
  }
  p:nth-of-type(odd){
    margin-left: 0.75em;
  }
}

.product--slideshow{
  order: -1;
  overflow: hidden;
  display: grid;
  grid-template-columns: repeat(6,1fr);
  grid-template-rows: 2fr 5.5rem;
  grid-row-gap: 4.5rem;
  @include mq(md){
    grid-template-rows: 2fr 7.5rem;
    grid-row-gap: 4rem;
    order: unset;
    height: 100%;
    padding: 0 3.5rem;
  }
  .swiper-container{
    grid-column: span 6;
    padding: 0 3.5rem;
    max-width: 503px;
    width: 100%;
    margin: 0 auto;
    @include mq(md){
      max-width: unset;
      padding: 0;
    }
  }
  .thumbs-container-wrap{
   grid-column: span 6;
  }
}


.product--related{
  &:before{
    content: "see also";
    display: block;
    width: 100%;
    text-align: center;
    @include margin(2.75 0 1.25);
  }
  @include mq(md){
    @include padding(0 1.5);
    display: flex;
    justify-content: flex-end;
    &:before{
      content: none;
    }
  }

}

.product--display-box-inner{
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  position: sticky;
  height: min-content;
  top: 0;
  gap: 1em;
  @include mq(md){
    grid-template-columns: repeat(2, calc(4.375rem));
  }
}

.product--display-box{
  @extend .box--grey;
  grid-column: span 1;
  align-self: start;
  padding: 0;
  height: min-content;
  margin: 0;
  aspect-ratio: 1/1;
  position: relative;
  &:after{
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    content: "display box";
  }
  @include mq(md){
    grid-column: span 2;
  }
}

.product--item-related{
  height: min-content;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  @include notouch(){
    &:hover{
      color: black !important;
      &:after{
        content: none !important;
      }
    }
  }
}

.product--display-box{
  display: none;
  justify-content: center;
  align-items: center;
  @include mq(md){
    display: flex;
  }
  img{
    z-index: 99;
    display: block;
    max-height: 100%;
    max-width: 100%;
    object-fit: contain;
  }
}
