.product-grid{
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(1, 1fr);
  grid-row-gap: calc(10.9375rem /1.5);
  padding: 0 calc(1 *  var(--base-space));
  grid-column-gap: 2rem;
  width: 100%;
  @include mq(md){
    padding: 0;
    grid-column-gap: 25%;
    grid-row-gap: 10.9375rem;
  }

  .product-grid-item:nth-of-type(odd){
    grid-column: span 1;
  }
  .product-grid-item:nth-of-type(even){
    grid-column: span 1;
  }
  .product-grid-item.big{
    img{
      position: relative;
      transform: none;
      top:0;
      width: 100%;
      height: auto;
      @include mq(md){
        margin: calc(0.25 * var(--base-space)) 0;
      }
    }
      grid-column: span 2;
      padding: 0 20%;
    .product-info{
      margin: 0 auto;
      @include mq(md){
        max-width: calc(33.3333% + 29.3%);
      }
    }
    .product--title--box{
      margin: 0 auto;
      @include mq(md){
        max-width: calc(33.3333% + 29.3%);
      }
    }
  }
}

/*.product-grid[data-category="all"]{
  .product-grid-item:nth-of-type(odd){
    grid-column: span 1;
  }
  .product-grid-item:nth-of-type(even){
    grid-column: span 1;
  }
  .product-grid-item.big{
    grid-column: span 2;
    padding: 0 16.75%;
  }
  .product-grid-item picture img{
  }
}*/

.product-grid-item.sold{
  .box--grey--outline{
    color: var(--color-gray-500);
  }
  .product--title--box{
    justify-content: start;
    &:before{
      content: "Sold —\00a0";
    }
  }
}

.product-grid-item{
  display: grid;
  grid-template-rows: min-content min-content min-content;
  .product-info{
    z-index: 1;
    width: 100%;
    display: flex;
    justify-content: space-between;
    .item-before{
      display: none;
      @include mq(md){
        display: initial;
      }
    }
  }
  .product--image--container{
    @include margin(0.5 0);
    background: white;
    overflow: visible;
    .box--aspect-ratio{
      position: relative;
      height: 0;
      padding-top: calc(100% - 0.75 * var(--base-space) * 2);
      @include mq(md){
        @include margin(0.25 0);
        padding-top: calc(100% - 0.75 * var(--base-space) * 2 - 1.6rem * 2);
      }
      &:hover{
        overflow: visible;
      }
    }
  }
  img{
    cursor: pointer;
    position: absolute;
    max-height: 100%;
    object-fit: contain;
    top: 50%;
    transform: translateY(-50%);
    display: block;
    transition: transform 0.1s $transition-timing-default, opacity 0.3s;
  }
  .square img{
    /*max-height: calc(100% - 1.5em);*/
  }
}

.product-grid-item:not(.big){
  @include mq(md){
    img{
      &:hover{
        top: 50%;
        transform: translateY(-50%) scale(200%);
        transform-origin: center center;
        transition: transform 0.1s $transition-timing-default;
      }
    }
  }

}
.product-grid{
  transition: opacity 0.1s $transition-timing-default;
  opacity: 1;
}
.product-grid.-loading{
  transition: opacity 0.1s $transition-timing-default;
  opacity: 0;
}

.box--grey--outline.product--title--box{
  display: none;
  &.show-xs{
    display: flex;
    &:empty{
    }
  }
  @include mq(md){
    display: flex;
    &.show-xs{
      display: none;
    }
  }
}
