* {
  margin: 0;
  padding: 0;
}

*::-webkit-scrollbar{
  display: none;
}

*{
  scrollbar-width: none;
  outline: none !important;
  -webkit-overflow-scrolling: touch;
}

input, button{
  -webkit-appearance: none;
  border-radius: 0;
}


html {
  font-family: var(--font-family-sans);
  line-height: var(--line-height);
  color: var(--color-black);
  background: var(--color-gray-100);
}

html, body{
  margin: 0;
  padding: 0;
/*  background: repeating-linear-gradient(
      0deg,
      black,
      black 1em,
      #ff4100 1em,
      #ff4100 2em,
      #fffb00 2em,
      #fffb00 3em,

  );*/
}
body{
  display: flex;
  flex-direction: column;
  min-height: 100%;
  position: fixed;
  width: 100%;
  height: auto;
  box-sizing: border-box;
  margin: 0 auto;
}
summary,
strong {
  font-weight: 600;
}
main {
  top:0;
  height: calc(var(--vh) * 100);
  overflow: scroll;
  background-color: white;
  @include padding(10 0);
  @include mq(md){
    @include padding(10 0);
  }
}

/* RESETS */

li {
  list-style: none;
}

button {
  font: inherit;
  background: none;
  border: 0;
  color: currentColor;
}
button:not(:disabled) {
  cursor: pointer;
}

h1,h2,h3,h4,h5,h6{
  font-weight: normal;
}

/* HELPER CLASSES */
.color-gray-100 {
  color: var(--color-gray-100);
}
.color-gray-200 {
  color: var(--color-gray-200);
}
.color-gray-300 {
  color: var(--color-gray-300);
}
.color-gray-400 {
  color: var(--color-gray-400);
}
.color-gray-500 {
  color: var(--color-gray-500);
}
.color-gray-600 {
  color: var(--color-gray-600);
}
.color-gray-700 {
  color: var(--color-gray-700);
}
.color-gray-800 {
  color: var(--color-gray-800);
}
.color-gray-900 {
  color: var(--color-gray-900);
}

.txt-left{
  text-align: left;
}
.txt-center{
  text-align: center;
}
.txt-right{
  text-align: right;
}

.text-xs {
  font-size: var(--text-xs);
}
.text-s {
  font-size: var(--text-s);
}
.text-m {
  font-size: var(--text-m);
}
.text-l {
  font-size: var(--text-l);
}
.text-xl {
  font-size: var(--text-xl);
}
.text-xxl {
  font-size: var(--text-xxl);
}
.text-3xl {
  font-size: var(--text-3xl);
}
.text-4xl {
  font-size: var(--text-4xl);
}
.text-5xl {
  font-size: var(--text-5xl);
}
.text-6xl {
  font-size: var(--text-6xl);
}

.stack-s > * + * {
  margin-top: var(--spacing-s);
}
.stack-m > * + * {
  margin-top: var(--spacing-m);
}

/* STANDARD COMPONENTS */
.blocks {
}
.text {
  line-height: 1.4;
}
.text > * {
  margin-block: 0;
}
.text > * + * {
  margin-block-start: var(--spacing-s);
}
.text--light{
  color: var(--color-gray-500);
}
a.text--light.active{
  color: black;
}
.table td,
.table th {
  text-align: start;
  padding: var(--spacing-xs);
}

.button{
  cursor: pointer;
  display: inline-block;
  padding: 0.4rem;
  min-width: 4.375rem;
  text-align: center;
  font-weight: 500;
  color: var(--color-black);
  border: 1px solid inherit;
  background-color: var(--color-white);
  position: relative;
}

.button[disabled] {
  color: var(--color-white);
  background-color: var(--color-gray-600);
}
.button-white {
  display: inline-block;
  padding: 0.5em;
  font-weight: 500;
  color: var(--color-black);
  background-color: var(--color-white);
}

.show-xs{
  display: block;
  @include mq(md){
    display: none !important;
  }
}
.hide-xs{
  display: none;
  @include mq(md){
    display: block;
  }
}

.error {
  color: var(--color-red);
}

.global-message {
  display: block;
  padding: var(--spacing-m);
  text-align: center;
  color: var(--color-white);
  background-color: var(--color-black);
}


h1{
  @include font(h1);
  @include fs(h1);
  font-weight: normal !important;
  margin: 0;
}

h2{
  @include font(h2);
  @include fs(h2);
  font-weight: normal !important;
  margin: 0;
}
