$transition-property-default: all;
$transition-duration-default: 0.2s;
$transition-timing-default: cubic-bezier(0.7, 0, 0.3, 1);
$transition-delay-default: 0s;

body{
  opacity: 0;
}

body.loaded{
  opacity: 1;
  transition: $transition-duration-default $transition-timing-default $transition-delay-default;
}

.lazyload,
.lazyloading {
  opacity: 0;
  height: auto;
  width: 100%;
}
.lazyloaded {
  opacity: 1;
  height: auto;
  transition: opacity $transition-duration-default;
}

.ls-is-cached{
  opacity: 1;
}
