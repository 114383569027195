.swiper-container{
  overflow: hidden;
  height: 100%;
  position: relative;
}

.swiper-wrapper, .swiper-slide{
  height: 100%;
}

.swiper-slide{
  max-height: 100%;
  object-fit: contain;
}

.swiper-slide picture{
  height: 100%;
  img{
    height: 100%;
    object-fit: contain;
  }
}

.swiper-button{
  z-index: 9;
  height: 100%;
  width: 50%;
  position: absolute;
  opacity: 0.5;
  &.swiper-next{
    right: 0;
    cursor: e-resize;
  }
  &.swiper-prev{
    left: 0;
    cursor: w-resize;
  }
}

.thumbs-container{
  overflow: hidden;
  height: 100%;
  grid-column: span 3;
  width: calc(5.5rem * var(--slidePerViewSm) + (var(--slidePerViewSm) - 1) * 10px);
  margin: 0 auto;
  @include mq(md){
    min-height: 120px;
    width: calc(120px * var(--slidePerViewMd) + (var(--slidePerViewMd) - 1) * 10px);
  }
  .swiper-wrapper{
   height: 100%;
    .swiper-slide{
      cursor: pointer;
      aspect-ratio: 1/1;
      filter: grayscale(100%);
      width: 5.5rem !important;
      @include mq(md){
        width: 120px !important;
      }
      &.swiper-slide-thumb-active{
        filter: none;
      }
    }
    .swiper-slide picture{
      height: 100%;
      width: 100%;
      img{
        min-width: 5.5rem;
        @include mq(md){
          min-width: 120px;
        }
        aspect-ratio: 1/1;
        height: 100%;
        width: 100%;
        object-fit: cover;
      }
    }
  }
}
