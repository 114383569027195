@keyframes rotate {
  from {
    transform: rotate(0turn);
  }
  to {
    transform: rotate(1turn);
  }
}

.global-message{
  position: absolute;
  top: 5rem;
  width: 100%;
  background-color: transparent !important;
  color: red !important;
}

main.checkout{
  padding-left: 1rem;
  padding-right: 1rem;
  @include mq(md){
    padding-right: 0rem;
    padding-left: 0rem;
  }
}
.checkout {
  display: block;
  grid-template-columns: repeat(10, 1fr);
  @include mq(md){
    display: grid;
    row-gap: 2rem;
    column-gap: 4rem;
  }
}

#checkout-form{
  order: -1;
  grid-column: span 6;
  @include mq(md){
    grid-column: 4/8;
  }
}

.cart[data-variant="checkout"]{
  grid-column: span 4;
  padding: 0;
  @include mq(md){
    grid-column: 8/11;
    padding-right: 4rem;
    margin-right: 0;
    max-width: 400px;
  }
}

.notice.text{
  grid-column: 4/8;
  display: none;
}

.checkout h2.field, .payment-methods h2{
  @include font(main);
  @include fs(main);
  padding-bottom: 0.6em;
  border-bottom: 1px solid;
  margin-top: 3rem;
  &:first-of-type{
    margin-top: 0rem;
  }
}

.payment-methods{
  border-bottom: 1px solid var(--color-gray-300);
}

.payment-methods h2{
  &:first-of-type{
    margin-top: 3rem;
  }
}

.checkout .error {
  grid-column-start: span 12;
}

.checkout.is-loading {
  color: var(--color-gray-700);
}

.payment-button{
  display: flex;
  justify-content: center;
  margin-top: 5rem;
}
.checkout button[type="submit"] {
  @extend .box;
  @extend .bkg-white;
  width: 100%;
  max-width: 18rem;
}
.checkout.is-loading button[type="submit"]::after {
  display: block;
}


@media (min-width: 1024px) {
  .checkout {
    /*grid-template-columns: 1fr auto;*/
    grid-auto-flow: dense;
    align-items: start;
  }

  .checkout .cart {
    position: sticky;
    top: 0;
    grid-row-end: span 2;
    min-width: 320px ;
  }
}

// CHECKOUT CART

.cart[data-variant="checkout"]{
  padding-top: 0;
  .table{
    padding: 0;
    padding-top: 0.75rem;
  }
  h2{
    @include font(main);
    @include fs(main);
    padding-bottom: 0.6em;
    border-bottom: 1px solid;
  }
  .cart-logo{
    display: none;
  }
  .t-header{
    display: none;
  }
  .t-row{
    border-color: var(--color-gray-300);
  }
}
