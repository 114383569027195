.filtering-container{
  @extend .xs-menu-box;
  @include mq(md){
    background-color: transparent;
  }
  ul{
    margin-bottom: 1em;
  }
  @include mq(md){
    position: static;
    margin-top: 0.6em;
    transform: none;
    left: auto;
    border: none;
    &:before{
      content: "";
      border: none;
      padding: 0 !important;
    }
  }
}

.filtering-menu{
  li{
    border-bottom: 1px solid lightgrey;
    position: relative;
    @include padding(0.3 0 0.3 1.5);
    a.active:after{
      content:"•";
      position: absolute;
      right: 0;
    }
    a{
      color: black;
      .category-total{
        display: block;
      }
    }
    @include mq(md){
      @include padding(0);
      border: none;
      a{
         color: var(--color-gray-500);
        @include notouch(){
          &:hover{
            color: black;
          }
        }
      }
      a.active:after{
        content: "";
      }
    }
  }
  @include mq(md) {
    @include padding(0 0 0 1.5);
    position: fixed;
  }
}

.filtering-container{
  .categories-menu .category-total{
    order: 0;
    @include mq(md){
    order: 1;
      text-align: right;
    margin-left: 0.25em;
    }
  }
  .categories-menu, .sort-menu, .hide-menu{
    padding: 1.5rem 1.5rem 0;
    @include mq(md){
      padding: 0;
    }
  }
  .categories-menu li{
    padding: 0.3rem 0;
    a{
      display: grid;
      grid-template-columns: 1.5rem 1fr;
      @include mq(md){
        display: flex;
      }
    }
    &:first-of-type{
      padding-left: 1.5rem;
      @include mq(md){
        padding: 0;
      }
    }
    @include mq(md){
      padding: 0;
    }
  }
  .sort-menu li{
    padding-left: 0;
    &:first-of-type{
      margin-bottom: 1em;
      border-bottom: none;
      color: black;
      @include mq(md){
        color: inherit;
      }
    }
  }
  .hide-menu li{
    padding-left: 0;
    &:last-of-type{
      border-bottom: none;
    }
  }
}




