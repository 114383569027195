main.about{
  padding: 7.5rem calc(1 * var(--base-space)) calc(1 * var(--base-space));
  @include mq(md){
    padding-top: 7.5rem;
    padding-bottom: 1.5rem;
  }
}

.about--text-container{
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-column: 3/9;
  align-content: start;
  text-align: center;
  h3{
    @include font(main);
    @include fs(main);
  }
  p{
    @include font(text);
    @include fs(text);
    margin-bottom: 1em;
  }
}

.about--credits{
  grid-column: 3/9;
  align-self: end;
  text-align: center;
}
