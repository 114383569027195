.details-cart {
  position: relative;
  z-index: 1;
}
.details-cart .toggle-cart {
  list-style: none;
  cursor: pointer;
}
.details-cart .toggle-cart:focus {
  outline: 0;
}

/* Mocks .details-cart summary */


.cart {
  width: 100%;
  font-feature-settings: "lnum";
  @include mq(md){
    width: calc(20vw + 1.5rem * 2);
    opacity:1;
  }
}

.cart[data-theme="dark"] {
  padding: var(--spacing-m);
}

.cart[data-theme="light"] {
  color: var(--color-black);
  background-color: white;
  border-left: 1px solid black;
}
.cart[data-variant="checkout"]{
  width: 100%;
}
.cart[data-variant="checkout"] .close-cart{
  display: none;
}

.cart[data-variant="checkout"] .cart-header{
  display: none;
}

.details-cart .cart {
  position: fixed;
  box-sizing: border-box;
  top: 0rem;
  right: -100%;
  display: flex;
  opacity: 0;
  flex-direction: column;
  transform: translateX(50%);
  border: 1px solid;
  height: calc(var(--vh) * 100 - (3.85 * var(--base-space)) - 0.75 * var(--base-space));
  @include mq(md){
    height: calc(var(--vh)*100 - 3rem);
    top: 1.5rem;
    transition: right 0s linear 0.2s, opacity 0.2s;
  }
}

.details-cart .cart.open{
  right: 50%;
  transform: translateX(50%);
  opacity: 1;
  z-index: 999;
  @include mq(md){
    transition: right 0s linear 0s, opacity 0.2s;
  }
}

.cart table {
  width: 100%;
}
.cart tfoot{
  margin-top: auto;
}
.cart a {
  text-decoration: none;
}
.cart img {
  width: 3rem;
}
.cart table {
  margin-bottom: var(--spacing-m);
}
.cart tr {
  vertical-align: top;
}
.cart th,
.cart td {
  padding: var(--spacing-s);
}
.cart th {
  text-align: left;
}


.cart tbody a {
  display: grid;
  grid-template-columns: 3rem auto;
  grid-template-rows: auto auto;
  column-gap: var(--spacing-m);
  padding: 0;
}
.cart tbody th img {
  grid-row-end: span 2;
}
.cart tbody th strong,
.cart tbody th small {
  /*grid-column-start: 2;*/
}
.cart tbody th small {
  font-size: inherit;
  color: var(--color-gray-500);
}
.cart tbody th strong {
  display: block;
  min-width: 6rem;
}
.cart td:nth-last-child(1),
.cart td:nth-last-child(2) {
  text-align: start;
}
.cart tfoot th,
.cart tfoot td {
  padding-top: var(--spacing-xs);
  padding-bottom: var(--spacing-xs);
}
.cart tfoot th {
  text-align: end;
}
.cart .button-white {
  margin-inline-start: auto;
}
.cart-info {
  text-align: center;
}


// NEW CART

.toggle-cart.show-xs{
  @extend a;
  background-color: white;
  margin-left: 1rem;
}

.cart{
  flex:1;
}

.cart .table{
  width: 100%;
  max-width: 100%;
  height: 100%;
  grid-template-rows: min-content;
  @include padding(1.5);
  @include mq(md){
    display: grid;
    width: 100%;
    max-width: 100%;
    height: 100%;
    grid-template-rows: min-content;
    overflow: hidden;
  }
}

.checkout-shipping{
  display: none !important;
}

.cart[data-variant="checkout"]{
  .checkout-shipping{
    display: flex !important;
  }
  .cart-shipping{
    display: none !important;
  }

  .t-foot{
    margin-top: 0;
  }
}

.cart-header{
  @include padding(0.3 1.5);
  border-bottom: 1px solid;
  min-height: 3em;
  align-items: center;
  display: flex;
  @include mq(md){
    display: block;
    min-height: auto;
  }
  p{
    flex: 1;
    display: flex;
    justify-content: space-between;
  }
  i{
    &:before{
      right: 0;
    }
  }
}

.cart-logo{
  @include padding(0 1.5);
  @include margin(4.15 0);
  text-align: left;
  display: none;
  @include mq(md){
    display: block;
  }
}

.cart-info{
  @include padding(0 1.5);
  text-align: left;
  margin-top: 3.5rem;
  @include mq(md){
    margin-top: 0;
  }
}

.close-cart{
  cursor: pointer;
}

.t-header{
  margin-top: 2rem;
  margin-bottom: 4rem;
  padding-bottom: 0.75rem;
  border-bottom: 1px solid;
  @include mq(md){
    margin-top: 0;
    margin-bottom: 0;
    padding-bottom: 0.5rem;
  }
}



.cart[data-variant="checkout"]{
  .t-header{
    margin-top: 0;
    margin-bottom: 0;
    padding-bottom: 0;
    border-bottom: none;
  }
}

.t-items{
  max-height: 100%;
  overflow: scroll;
  margin-top: -1px;
}

.t-item{
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 0.75em;
  border-bottom: 1px solid;
  align-items: baseline;
  @include mq(md){
    grid-template-columns: repeat(2, 1fr);
  }
}
.t-item .i-name{
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding-bottom: 0.5rem;
  .text-xs{
    @include fs(main);
    @include mq(md){
      font-size: var(--text-xs);
    }
  }

}

.t-item .i-quantity{
  align-items: end;
}

.t-item .t-row:last-of-type{
  border-bottom: 0;
}

.t-row{
  border-bottom: 1px solid var(--color-gray-300);
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  padding: 0.5rem 0;
}

.t-foot{
  display: grid;
  grid-template-columns: repeat(2,1fr);
  grid-column-gap: 0.75em;
  align-self: end;
  width: 100%;
  margin-bottom: 0.6rem;
  margin-top: 2rem;
  @include mq(md){
    margin-bottom: 0.6rem;
    margin-top: calc(1.5 * var(--base-space));
    grid-template-columns: repeat(2,1fr);
    grid-column-gap: 0.75em;
  }
  @include mq(lg){
    margin-bottom: 0.9rem;
  }
}

.t-foot-header{
  grid-column: span 2;
}

.t-foot .cart-shipping h4{
  display: grid;
  grid-template-columns: repeat(2,1fr);
  @include mq(md){
  }
  @include mq(lg){
  }
  span{
    white-space: nowrap;
  }
}

.t-foot .sum, .t-foot .tax{
  grid-column: 2;
}

.t-foot .tax{
  border-bottom: none;
  white-space: nowrap;
  gap: 1em;
}

.check-out--btn-container{
  padding: 0 calc(1.5 * var(--base-space)) calc(1.5 * var(--base-space));
  .button{
    margin: 0 !important;
    max-width: unset !important;
  }
}


// ORDER CART

.link-cart{
  display: none !important;
  @include mq(md){
    display: block !important;
  }
}

.order{
  font-size: 0.938rem;
}

.order .content-wrapper{
  font-family: "Helvetica";
  font-weight: 600;
  letter-spacing: normal !important;
  line-height: 1.2rem !important;
  .text, h4{
    font-family: "Helvetica";
    font-weight: 600 !important;
    letter-spacing: normal !important;
    line-height: 1.2rem !important;
  }
  h4{
    text-transform: capitalize;
    font-size: 0.7rem;
  }
  .cart{
    width: 100%;
  }
  .invoice-header{
    grid-column: span 12;
    display: grid;
    grid-template-columns: 1fr 1fr;
    .invoice-info{
      display: flex;
      gap: 0.5rem;
      h4:first-of-type{
        margin-right: auto;
      }
    }
  }

  .invoice-adress{
    padding-left: 50%;
    grid-column: span 12;
    margin-bottom: 2rem;
    margin-top: 2rem;
  }
  .t-head{
    margin-top: 1.5rem;
    display: grid;
    grid-template-columns: 1fr 16.666% 16.666% 16.666%;
    border-bottom: 1px solid;
  }
  .t-item{
    grid-template-columns: 1fr 16.666% 16.666% 16.666%;
    border-bottom: none;
  }

  .t-foot{
    display: block;
    margin-top: 1rem;
    margin-bottom: 2rem;
  }
  .t-foot .t-foot-row{
    display: grid;
    justify-content: end;
    grid-template-columns: 16.666% 16.666%;
  }
  .t-foot .grand-total{
    margin-top: 1rem;
    display: grid;
    justify-content: end;
    grid-template-columns: 33.333% 16.666%;
    border-bottom: 1px solid;
  }

  .invoice-footer{
    display: grid;
    grid-column: span 12;
    grid-template-columns: 50% 50%;
    .print{
      margin-top: 2rem;
      grid-column: span 12;
      padding-left: 50%;
    }
  }

}

@media print {
  html{
    font-size: 16px !important;
  }
  .content-wrapper{
    padding-top: 0 !important;
  }
  header{
    display: none;
  }
  .footer{
    display: none;
  }
  .print{
    display: none;
  }
}

