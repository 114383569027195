.order .cart {
  background-color: var(--color-white);
  padding: var(--spacing-l);
}

.show-print{
  display: none;
}

main.order{
  padding-left: 1rem;
  padding-right: 1rem;
  padding-bottom: 3rem;
  @include mq(md){
    padding-left: 0;
    padding-right: 0;
  }
  .content-wrapper{
    aspect-ratio: 1/1.2;
    grid-column: 4/8;
    height: auto;
    display: grid !important;
    grid-template-rows: min-content min-content min-content min-content 1fr;
    padding: 1rem;
    border: 1px solid black;
    max-width: 600px;
    align-content: start;
    margin: 0 auto;
    @include mq(md){
      min-width: 600px;
      height: 100%;
    }
  }
  * > [data-width="1/1"]{
    grid-column: span 12;
  }
}

.order .cart {
  background-color: var(--color-white);
  padding: 0;
}

.print{
  button{
    width: 100%;
  }
}


.invoice-adress{
  margin-top: 3rem;
  grid-column: 6/13;
}

.order-review{
  padding-top: 0.4em;
  padding-left: 50%;
  border-top: 1px solid;
}


.order .content-wrapper{
  position: relative;
  font-family: "Helvetica";
  font-weight: 400;
  letter-spacing: normal !important;
  line-height: 1.2rem !important;
  font-size: 1.2em;
  .text, h4{
    font-family: "Helvetica";
    font-weight: 400 !important;
    letter-spacing: normal !important;
    line-height: 1.15em !important;
  }
  .text-xs{
    font-size: 0.7em;
    line-height: 1.25em;
  }
  h4{
    text-transform: capitalize;
    font-size: 0.7em;
  }
  .cart{
    width: 100%;
  }
  .invoice-header{
    grid-column: span 12;
    display: grid;
    grid-template-columns: 1fr 1fr;
    .invoice-info{
      display: flex;
      gap: 0.5rem;
      h4:first-of-type{
        margin-right: auto;
      }
    }
  }

  .invoice-adress{
    padding-left: 50%;
    grid-column: span 12;
    margin-bottom: 2rem;
    margin-top: 2rem;
  }
  .t-head{
    margin-top: 1.5rem;
    display: grid;
    grid-template-columns: 1fr 16.666% 16.666% 16.666%;
    grid-gap: 0em;
    border-bottom: 1px solid;
    padding-bottom: 0.15em;
  }
  .t-item{
    grid-template-columns: 1fr 16.666% 16.666% 16.666%;
    grid-gap: 0em;
    border-bottom: none;
    padding: 0.2em 0;
  }

  .t-foot{
    display: block;
    margin-top: 1rem;
    margin-bottom: 2rem;
  }
  .t-foot .t-foot-row{
    display: grid;
    justify-content: end;
    grid-template-columns: 16.666% 16.666%;
   /* grid-gap: 0.75em;*/
  }
  .t-foot .grand-total{
    margin-top: 1rem;
    display: grid;
    justify-content: end;
    grid-template-columns: 33.333% 16.666%;
    border-bottom: 1px solid;
    padding-bottom: 0.15em;
  }

  .invoice-footer{
    display: flex;
    flex-wrap: wrap;
    .text{
      width: 50%;
    }
    .print{
      margin-top: 2rem;
      grid-column: span 12;
      padding-left: 50%;
      width: 100%;
      display: flex;
      align-items: end;
      button{
        width: 100%;
        @extend .box--black-hover;
        text-align: left;
        border: 1px solid;
        font-family: "Helvetica";
        letter-spacing: normal !important;
        line-height: 1.2em !important;
      }
    }
  }

}

@media print {
  html, body{
    font-size: 16px !important;
    position: relative;
    height: auto;
    overflow: auto;
  }
  main{
    overflow: auto;
    padding-top: 3.5rem !important;
  }
  .content-wrapper{
    padding-left: 2rem !important;
    padding-right: 2rem !important;
    border: none !important;
    max-width: 100% !important;
  }
  .header{
    position: static;
  }
  .header > *:not(.site-title.show-print){
    display: none !important;
  }
  .show-print{
    display: block !important;
    font-size: 2.5rem;
  }
  .footer{
    display: none;
  }
  .print{
    display: none !important;
  }
}
