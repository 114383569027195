.quantity {
  position: relative;

  input {
    width: 5.5em;
    line-height: 1.65;
    float: left;
    display: block;
    padding: 0;
    margin: 0;
    border: none;
    text-align: center;
    padding: 0 0.65rem 0 1.25rem;
    background: transparent;

    &:focus {
      outline: 0;
    }
  }
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button
{
  -webkit-appearance: none;
  margin: 0;
}

input[type=number]
{
  -moz-appearance: textfield;
}

.quantity-nav {
  position: absolute;
  top:0;
  left: 0;
  height: 1.25rem;
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.quantity-button {
  position: relative;
  cursor: pointer;
  width: 1.25rem;
  text-align: center;
  color: #333;
  font-size: 14px;
  font-family: "Trebuchet MS", Helvetica, sans-serif !important;
  line-height: 1.45;
  user-select: none;

  &--up {
    position: absolute;
    right: 0;
    width: auto;
  }

  &--down {
    position: absolute;
    left: 0;
  }
}
