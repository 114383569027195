.ss-main .ss-single-selected{
  @extend .field;
  line-height: 1.2em !important;
  padding: var(--spacing-xs);
  padding-left: 1em;
  height: auto;
  border-radius: 0;
  border: none;
  border-bottom: 1px solid var(--color-gray-300);
}

.ss-main .ss-single-selected .placeholder{
  line-height: 1.2em !important;
}
.ss-main .ss-single-selected .ss-arrow{
  margin-right: 0;
}
.ss-main .ss-single-selected .ss-arrow span{
  border-color: var(--color-gray-300);
  border-width: 0 1px 1px 0;
}
