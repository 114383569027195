.xs-menu-box{
  position: fixed;
  box-sizing: border-box;
  top: calc(3.85 * var(--base-space));
  left: 50%;
  transform: translateX(-50%);
  height: calc(var(--vh) * 100 - (3.85 * var(--base-space)) - 0.75 * var(--base-space));
  width: 70%;
  opacity: 1;
  background-color: white;
  z-index: 99;
  flex-direction: column;
  transition: right 0s linear 0.2s, opacity 0.2s;
  border: 1px solid;
  display: none;
  &.show{
    display: flex;
  }
  &:before{
    content: attr(data-name);
    min-height: 3em;
    display: flex;
    align-items: center;
    justify-content: center;
    @include padding(0.3 1.5);
    border-bottom: 1px solid;
  }
  @include mq(md){
    position: static;
    top: 0;
    left: 0;
    transform: translateX(0);
    height: auto;
    width: 100%;
    flex-direction: row;
    display: grid;
    z-index: 9;
    &:before{
      content: none;
      text-align: center;
      padding: unset;
      border-bottom: none;
    }
  }
}

.header{
  position: fixed;
  width: 100%;
  z-index: 99;
  display: flex;
  align-items: center;
  @include padding(1);
  @include mq(md){
    display: block;
    @include padding(1.5);
  }
}

.header .box{
  min-height: 3em;
  display: flex;
  align-items: center;
  justify-content: center;
  @include mq(md){
    min-height: unset;
    display: block;
  }
}

.header .nav-toggler, .header .filter-toggler{
  @include mq(md){
    display: none;
  }
}
.header .filter-toggler{
  margin-left: 1rem;
  order: 2;
}

.header .nav-toggler{
  margin-right: 1rem;
  @include mq(md){
    margin-right: auto;
  }
}
#home  .header nav.show{
  a:nth-of-type(2){
    order: 3;
    margin-bottom: auto;
  }
  a:nth-of-type(5){
    order: 4;
  }
  a:nth-of-type(6){
    order: 4;
  }
  a.inst{
    order:7;
  }
}
.header nav.show{
  @extend .xs-menu-box;
  &:before{
    margin-bottom: 1.5rem;
  }
  .box{
    display: flex;
    max-width: 5rem;
    margin-left: 1.5rem;
  }
  a{
    margin-bottom: 1.5rem;
  }
  a:nth-of-type(4){

  }
  a.inst{
    order:4;
  }
  a:nth-of-type(2){
    order: 3;
    margin-bottom: auto;
    margin-top: auto;
  }
  a:nth-of-type(5){
    order: none;
  }
  a:nth-of-type(6){
    order: 4;
  }
  .site-title{
    display: none;
    @include mq(md){
      display: block;
      max-width: unset;
    }
  }
  .instagram, .legal{
    display: flex;
    @include mq(md){
      display: none;
    }
  }
  a{
    @include mq(md){
      order: unset !important;
    }
  }
}
.header nav{
  .box{
    display: none;
  }
  .button{
    align-self: center;
  }
  @include mq(md){
    .box{
      display: inline-block;
      &.inst, &.legal{
        @include mq(md){
          display: none;
        }
      }
    }
    display: grid;
    position: relative;
    grid-template-columns: min-content min-content 1fr min-content min-content;
    width: 100%;
    gap: 1em;
  }
}

.site-title{
  line-height: 0.7;
  margin-left: auto !important;
  margin-right: auto !important;
  opacity: 1;
  transition: opacity 0.1s;
  &.hide{
    opacity: 0;
    transition: opacity 0.1s;
  }
}

.box.site-title.width-2{
  line-height: unset;
  margin-top: 0;
  margin-bottom: 0;
}

header .box{
  background: white;
}

header .menu-container.cart-is-open{
  border: none;
}



