.grid {
  display: block;
}

@include mq(md){
  .grid {
    display: grid;
    grid-template-columns: repeat(10, 1fr);
    width: 100%;
  }


  .grid > [data-width="1/1"],
  .grid > [data-width="10/10"],
  .grid > [data-width="2/2"],
  .grid > [data-width="3/3"],
  .grid > [data-width="4/4"],
  .grid > [data-width="6/6"] {
    grid-column-start: span 10;
  }

  .grid > [data-width="1/5"]{
    grid-column-start: span 2;
  }
  .grid > [data-width="2/5"]{
    grid-column-start: span 4;
  }
  .grid > [data-width="3/5"]{
    grid-column-start: span 6;
  }
  .grid > [data-width="4/5"]{
    grid-column-start: span 8;
  }
  .grid > [data-width="5/5"]{
    grid-column-start: span 10;
  }
}
