.field label {
  display: block;
  font-weight: 600;
}

.field label span {
  color: var(--color-gray-500);
}

.field input[type="text"],
.field input[type="email"],
.field input[type="textarea"],
.field textarea,
.field select,
.ss-single-selected,
.field .StripeElement {
  margin-top: var(--spacing-s);
  box-sizing: border-box;
  padding: var(--spacing-s);
  border: 1px solid var(--color-gray-300);
  font-size: inherit;
  font-family: inherit;
  line-height: inherit;
  background-color: var(--color-white);
  resize: none;
}
.field label{
  display: inline-block;
  white-space: nowrap;
}

.field .custom-select{
  margin: var(--spacing-s) 0;
  border: 1px solid var(--color-gray-300);
}
.field[hidden]{
  display: none !important;
}
.field[data-width="1/1"]{
  display: flex;
  align-items: end;
  label{
    font-weight: normal;
    border-bottom: 1px solid var(--color-gray-300);
    padding: var(--spacing-xs) 0;
  }
  input{
    flex: 1;
    border: none;
    border-bottom: 1px solid var(--color-gray-300);
    padding: var(--spacing-xs);
  }
}

// Textarea field

.field[data-type="textarea"]{
  display: block;
  margin-top: 0.6em;
  label{
    display: block;
    border-bottom: 0;
  }
  textarea{
    padding: 0;
    border: none;
    border-bottom: 1px solid var(--color-gray-300);
    width: 100%;
  }
}

// Checkboxes field

.field[data-type="radio"],
.field[data-type="checkbox"] {
  display: flex;
  align-items: start;
  padding: var(--spacing-xs) 0;
  border-bottom: 1px solid var(--color-gray-300);
  margin-top: 1em;
}
.field[data-type="radio"]{
  grid-column: span 3;
  border-bottom: none;
}
.field[data-type="radio"] label,
.field[data-type="checkbox"] label {
  font-weight: 400;
  padding: 0;
  line-height: 1.65em;
  border: none;
}
.field[data-type="radio"] input,
.field[data-type="checkbox"] input {
  height: calc(1rem * var(--line-height));
  margin-right: var(--spacing-s);
  flex-shrink: 0;
  border-radius: 0;
  flex: unset;
  border-bottom: 1px solid #000;
}

.field .error {
  margin-top: var(--spacing-xs);
  font-size: var(--text-s);
}


.field[data-payment-method="credit-card-sca"]{
  align-items: end;
  label{
    border-bottom: none;
  }
  #stripe-card{
    width: 100%;
    border: none;
    padding: var(--spacing-xs);
    padding-left: 2em;
    margin-top: 0;
  }
}


// Payment method

.payment-methods .field[data-type="radio"]{
  margin-top: 0;
  @include mq(md){
    margin-top: 1em;
  }
  &:first-of-type{
    margin-top: 1em;
  }
}
